
import { lazy } from 'react';


export default class BBIFCJSViewerDescriptor{


static getDescription() {
    return {
      name: 'BB Ganttchart',
    
      component: lazy(() =>  import('./BBGanttChart.js')),
      label: 'BB Ganttchart (under development)',
      variables: [
        {
          name: 'query',
          type: 'yasgui',
          label: 'use parameter ?id ?uri ?name ?start ?end ?progress ?dependencies ?graph ?code'
        },
        
        {
          name: 'editable',
          type: 'boolean',
          label: 'editable',
        
        },
       
        {
          name: 'timestampParameters',
          type: 'text',
          label:
            'sets a timestamp after main event. multiple parameters via comma seperation',
        }
      ]
    }
}
}