import { store } from "../store"

export type DebuggerState = {
  enabled: boolean
}

const initialState: DebuggerState = {
  enabled: false
}

export type DebuggerAction
  = {type: 'SET_DEBUGGER_ENABLED', enabled?: boolean}

export default function debuggerReducer(state: DebuggerState = initialState, action: DebuggerAction): DebuggerState {
  switch (action.type) {
    case "SET_DEBUGGER_ENABLED":
      return { ...state, enabled: Boolean(action.enabled) }
  }

  return state
}

export function setDebuggerEnabled(enabled: boolean) {
  const action: DebuggerAction = {type: 'SET_DEBUGGER_ENABLED', enabled}
  store.dispatch(action)
}
