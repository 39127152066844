import { lazy } from 'react';
export default class BBMPythonServerPageDescriptor  {

static getDescription() {
    return {
      name: 'BBPythonServerPage',
      component: lazy(() =>  import('./BBPythonServerPage')),
      label: 'BB Python Server page (experimental)',
      type:"experimental",
      variables: [
       

        {
          name: 'extra script on iframe',
          type: 'text',
          label: 'something to manage extra scripts. Not yet implemented. '
        },
        {
          name: 'url',
          type: 'text',
          label: 'python server page url. please add ?embed=true'
        },
       
        
      ],
    }
  }
}
