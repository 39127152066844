import React from 'react'
import PublishUtils from '../../../helpers/PublishUtils'

export const configOptions = [
  {
    name: 'query',
    type: 'yasgui',
    label: 'Query where all possible sources for the video queried as `?src_URL`s',
  },
  {
    name: 'timeJumpTopic',
    type: 'text',
    label: 'Topic (variable) for which integer updates will make the video seek to that point in time as seconds',
  },
  {
    name: 'disableSnapshotButton',
    type: 'boolean',
    label: 'disable snapshot button',
  },
  {
    name: 'labelSnapshotButton',
    type: 'text',
    label: 'label snapshot button',
  },
  {
    name: 'command',
    type: 'text',
    label: 'command for server  {validateGarantie,uploadAnyXLSX,uploadAnyFile:ruletag}',
  },
  {
    name: 'ruleParams',
    type: 'json',
    label: 'parameters for the rulechain, for example {"graph":"{{graph}}"}',
  },
  PublishUtils.getTimestampParametersDef(),
]

export const videoPlayerPOCDescription = {
  name: 'VideoPlayer POC',
  component: React.lazy(() => import('./Widget')),
  label: 'VideoPlayer (AMS POC version)',
  variables: configOptions,
}
