import { lazy } from 'react';
export default class BBMenubarDescriptor  {

static getDescription() {
    return {
      name: 'BB Menubar',
      component: lazy(() =>  import('./BBMenubar')),
      label: 'Menubar (Experimental)',
      type:"experimental",
      variables: [
        {
          name: 'query',
          type: 'yasgui',
          label:
            'use parameter ?uri ?label ?parent ?command (not yet implemented) '
           
        },
        {
          name: 'perspectives',
          type: 'json',
          label: 'a JSON array for defining perspectives in the for [ { "label": "label","key":"home","icon":"pi pi-fw pi-inbox","children":[] }]',
        },

        {
          name: 'menubarconfig',
          type: 'json',
          label: 'JSON configuration for the menubar.  eg {"id": "chatagent","icon": "pi pi-help","label": "Chat","subcommand": "popup"} other subcommands are runrule and perspectives. you can use item:[] to specify children' 
        },
        {
          name: 'areas',
          type: 'json',
          //label: 'Column widths or row heights (separated by commas). Value can be a number (which will be set as flex values/verhoudingswaarden) or a px/% value',
          label: 'area definitions'
        }
        
      ],
    }
  }
}
