import { lazy } from 'react';
export default class BBMenubarDescriptor  {

static getDescription() {
    return {
      name: 'BB Component',
      component: lazy(() =>  import('./BBComponent')),
      label: 'BB Component (Experimental)',
      type:"experimental",
      variables: [
       

        {
          name: 'url',
          type: 'text',
          label: 'use an url to another app to re-use the main widget.'
        },
        {
          name: 'urlData',
          type: 'json',
          //label: 'Column widths or row heights (separated by commas). Value can be a number (which will be set as flex values/verhoudingswaarden) or a px/% value',
          label: 'a copy of the config data from the app specified by the url (not implemented yet)'
        }
        
      ],
    }
  }
}
