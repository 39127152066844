import React from 'react'
import {z} from 'zod'

import {useSelector} from '../../../../store'

import {JsonEditor} from '../../../JsonEditor'
import {Tabs, TabsContent, TabsList, TabsTriggerAlt} from '../../../ui-primitives/tabs'
import {Cross2Icon, SymbolIcon, PlusIcon, Link2Icon, LinkBreak2Icon, PlayIcon} from '@radix-ui/react-icons'
import {Label} from '../../../ui-primitives/label'
import {Input} from '../../../ui-primitives/input'
import {Switch} from '../../../ui-primitives/switch'
import {Button} from '../../../ui-primitives/button'
import {InputWithSuggestions} from '../../../ui-primitives/input-with-suggestions'

import {DndContext, PointerSensor, UniqueIdentifier, useSensor, useSensors} from '@dnd-kit/core'
import {arrayMove, SortableContext} from '@dnd-kit/sortable'
import {SortableItem} from '../../../sortable/sortable-item'
import {Tooltip} from '@mui/material'
import {parseSparqlParams} from '../../../../utils/parse-sparql-params'
import { Draft, produce } from 'immer'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../../ui-primitives/select'
import { Alert, AlertDescription } from '../../../ui-primitives/alert'
import {actionColumnConfigSchema, ColumnConfig, createDefaultActionColumnConfig, CUSTOM_COMBINATION_PATH,
        EditableColumnConfig, getDefaultEditableColumnConfig, getDefaultRowEditorConfig, parseColumn,
        refineRowEditorCombinations, stateSchema, TablePlusPlusCustomConfig, TableppDataColumnConfig,
        toObjectTypeLabel, toComponentTypeLabel, toWhenEmptyFieldOptionLabel,
        isDataColumn} from '../config-schema'
import { mapRecordToList } from '../../../../utils/typescript'

export function TablePPCustomConfigView(props: {
  name: string, customConfig: TablePlusPlusCustomConfig,
  onChange: (name: string, value: TablePlusPlusCustomConfig) => void
  setConfigFeedback: (type: 'valid' | 'warning' | 'error', message?: React.ReactNode) => void
}) {
  const [sparqlVarNames, setSparqlVarNames] = React.useState<string[]>([])
  const [selectVarNames, setSelectVarNames] = React.useState<string[]>([])
  const [currentQuery, setCurrentQuery] = React.useState<string>('')
  const [tabId, setTabId] = React.useState<string>(props.customConfig.columnConfigs[0]?.id)

  const dataYasqe = useSelector(({currentViewer: {activeYasqes}}) => {
    if (activeYasqes.length === 0) console.error('no sparql editor window was found')
    if (activeYasqes.length > 1) console.error('could not identify which sparql-editor window is used for data-querying')
    return activeYasqes.length === 1 ? activeYasqes[0] : null
  })

  React.useEffect(() => {
    if (!dataYasqe) return
    const updateSPARQLVarNames = () => {
      setCurrentQuery(dataYasqe?.getValue() as string)
      const sparqlVarNames = dataYasqe.getVariablesFromQuery().map(s => s.slice(1))
      const selectVarNames = parseSparqlParams(dataYasqe.getValueWithoutComments())
      setSparqlVarNames(sparqlVarNames)
      setSelectVarNames(selectVarNames)
    }
    updateSPARQLVarNames()
    dataYasqe.on('blur', updateSPARQLVarNames)
  }, [dataYasqe, setSparqlVarNames])

  const validateConfig = () => {
    const parsed = stateSchema.superRefine((config, ctx) => {
      refineRowEditorCombinations(config, ctx, currentQuery)
    }).safeParse(props.customConfig)

    if (parsed.success) props.setConfigFeedback('valid')
    else props.setConfigFeedback('error', (
      <InvalidCombinationReports parsedError={ parsed.error} />))
  }

  React.useEffect(() => validateConfig(), [currentQuery, props.customConfig])

  // @ts-ignore
  window.y = dataYasqe

  // these make it reasonably easy to trigger onClick events on drag-and-drop items
  const reasonablyClickableSensors = useSensors(useSensor(PointerSensor, {activationConstraint: {distance: 8}}))

  const isLinked = (column: ColumnConfig) => selectVarNames.includes(column.id)
  const isActionColumn = (column: ColumnConfig) => actionColumnConfigSchema.safeParse(column).success

  const updateConfig = (fn: (draft: Draft<TablePlusPlusCustomConfig>) => void) =>
    props.onChange(props.name, produce(props.customConfig, fn))

  const replaceColumn = (targetId: string, newColumn: ColumnConfig) => updateConfig(draft => {
    draft.columnConfigs = draft.columnConfigs.map(column => column.id === targetId ? newColumn : column)
  })

  const updateColumnPro = <T extends ColumnConfig>(column: T, update: (draft: Draft<T>) => void) => {
    updateConfig(draft => {
      const colIdx = draft.columnConfigs.findIndex(c => c.id === column.id)
      if (colIdx === -1) throw new Error(`columnId ${column.id} could not be found`)
      update(draft.columnConfigs[colIdx] as Draft<T>)
    })
  }

  const updateColumn = <T extends ColumnConfig, K extends keyof Draft<T>, V extends Draft<T>[K]>
    (targetColumn: T, key: K, value: V) => updateColumnPro(targetColumn, c => c[key] = value)

  const updateColumnEditablePro = <T extends TableppDataColumnConfig>
    (column: T, update: (draft: Draft<EditableColumnConfig>) => void) =>
      updateColumnPro(column, (draft) => {
        if (!draft.editable) draft.editable = getDefaultEditableColumnConfig()
        update(draft.editable)
      })

  const dataVarNames = sparqlVarNames.filter(s => !s.includes('_'))
  const dataSelectVarNames = selectVarNames.filter(s => !s.includes('_'))

  const unlinkedVarNames = dataVarNames.filter(varName =>
    !props.customConfig.columnConfigs.some(column => column.id === varName))
  const unlinkedSelectVarNames = dataSelectVarNames.filter(varName =>
    !props.customConfig.columnConfigs.some(column => column.id === varName))

  const addDataColumn = () => {
    const newColumn = { width: 'auto', filterable: true, sortable: true,
      id: unlinkedSelectVarNames?.[0] ?? `data-${Date.now()}`,
      label: unlinkedSelectVarNames?.[0] ?? ''}
    props.onChange(props.name, produce(props.customConfig, draft => {
      draft.columnConfigs.push(newColumn)
    }))
    setTabId(newColumn.id)
  }

  const addActionColumn = () => {
    const newColumn = createDefaultActionColumnConfig()
    updateConfig(draft => { draft.columnConfigs.push(newColumn) })
    setTabId(newColumn.id)
  }

  const removeColumn = (targetColumn: ColumnConfig) =>
    props.onChange(props.name, produce(props.customConfig, draft => {
      draft.columnConfigs = draft.columnConfigs.filter(column => column.id !== targetColumn.id)
    }))

  const autoFillColumns = () => {
    if (!unlinkedSelectVarNames.length) return
    const newColumns = unlinkedSelectVarNames.map(name =>
      ({id: name, label: name, width: 'auto', filterable: true, sortable: true,
        editable: props.customConfig.rowEditor?.enabled ? getDefaultEditableColumnConfig() : undefined
      }))
    props.onChange(props.name, produce(props.customConfig, draft => {
      draft.columnConfigs.push(...newColumns)
    }))
    setTabId(newColumns[0].id)
  }

  const reorder = (activeId: UniqueIdentifier, overId: UniqueIdentifier) => {
    const oldIdx = props.customConfig.columnConfigs.findIndex(c => c.id === activeId)
    const newIdx = props.customConfig.columnConfigs.findIndex(c => c.id === overId)
    props.onChange(props.name, produce(props.customConfig, draft => {
      draft.columnConfigs = arrayMove(props.customConfig.columnConfigs, oldIdx, newIdx)
    }))
  }

  return (
    <div  className="flex flex-col gap-2 w-full p-2 border-2 rounded-lg bg-muted text-foreground shadow-lg" style={{
      fontFamily: '"Exo 2 Variable", sans-serif'
    }}>

      <div className="grid w-full items-center gap-4 p-4 mt-0 bg-background rounded-lg">
        <ToggleInput label="enable row editing"
           checked={props.customConfig.rowEditor?.enabled}
            onCheckedChange={v => updateConfig(draft => {
              if (!draft.rowEditor) draft.rowEditor = getDefaultRowEditorConfig()
              draft.rowEditor.enabled = v
              for (const column of draft.columnConfigs) {
                if ('sortable' in column && column?.editable === undefined)
                  column.editable = getDefaultEditableColumnConfig()
              }
            })} />
        {props.customConfig.rowEditor?.enabled && (<>
          <TextInput label="Default graph"
            defaultValue={props.customConfig.rowEditor.graph}
            onChange={e => updateConfig(draft => {
              if (!draft.rowEditor) draft.rowEditor = getDefaultRowEditorConfig()
              draft.rowEditor.graph = e.target.value
            })} />
        </>)}
      </div>

      <div className="flex w-full gap-2">
        <Tooltip title={dataSelectVarNames.length === 0 ? "Autofill with SELECT * is not supported" : undefined}>
          <span>{/* need this <span> for MUI tooltip otherwise it won't show the tooltip on a disabled button -_-*/}
            <Button disabled={dataSelectVarNames.length === 0} onClick={autoFillColumns} className="gap-2">
              <SymbolIcon /><span>Auto fill</span></Button></span></Tooltip>
        <Button onClick={addDataColumn} className="gap-2"><PlusIcon /><span>Add sparql column</span></Button>
        <Button onClick={addActionColumn} className="gap-2"><PlusIcon /><span>Add actions column</span></Button>
      </div>

      <div className="shadow rounded-lg">
        <Tabs value={tabId} onValueChange={setTabId}>
          <DndContext sensors={reasonablyClickableSensors} onDragEnd={(e) => {
            if (!e.active || !e.over) return
            reorder(e.active.id, e.over.id)
          }}>
            <TabsList className="w-full flex flex-row flex-wrap justify-start p-0 h-auto mx-[-1px] bg-transparent">
              <SortableContext items={props.customConfig.columnConfigs.map(c => c.id)}>{props.customConfig.columnConfigs.map(column => (
                <SortableItem id={column.id} key={column.id}>
                  <TabsTriggerAlt className="!pr-2 gap-2 [&>button]:hover:opacity-100" value={column.id} aria-label={column.label}>
                    {isActionColumn(column)? <PlayIcon/> : isLinked(column)? <Link2Icon/> : <LinkBreak2Icon/>} {column.label}
                    <Button variant="ghost"
                            aria-label='Remove column'
                            className="rounded size-5 p-1 hover:bg-slate-300 opacity-50 transition-opacity "
                            onPointerDown={e => e.stopPropagation() /* stop radix from selecting the tab */}
                            onMouseDown={e => e.stopPropagation()  /* stop radix from selecting the tab */}
                            onFocus={e => e.stopPropagation() /* stop radix from selecting the tab */}
                            onClick={e => {
                              e.stopPropagation()  /* stop radix from selecting the tab */
                              removeColumn(column)
                            }}>
                      <Cross2Icon />
                    </Button>
                  </TabsTriggerAlt>
                </SortableItem>
              ))}</SortableContext>
            </TabsList>
          </DndContext>

          {props.customConfig.columnConfigs.map(column => {
            const parsed = parseColumn(column)
            const tabsContentCss = "grid w-full items-center gap-4 p-4 mt-0 bg-background rounded-lg"

            if (parsed.dataColumn.success && !parsed.actionColumn.success) {
              const dataColumn = parsed.dataColumn.data
              return (<>
                <TabsContent key={dataColumn.id} value={dataColumn.id} className={tabsContentCss}>
                  <TextInputWithSuggestions
                    label="SPARQL variable name"
                    placeholder={`${isLinked(dataColumn) ? '🔗' : '⛓️‍💥'} ${dataColumn.id}`}
                    selected={dataColumn.id}
                    select={id => {
                      updateColumn(dataColumn, 'id', id)
                      setTabId(id)
                    }}
                    suggestions={unlinkedVarNames}
                    noSuggestionsPlaceholder={dataVarNames.length === 0
                      ? "current query does not contain any sparql identifiers"
                      : "there are no unused sparql identifiers remaining"} />
                  <TextInput label="Header label"
                    defaultValue={dataColumn.label}
                    onChange={e => updateColumn(dataColumn, 'label', e.target.value)} />
                  <TextInput label={<>Topic to publish to when clicked with <code>_URI</code> modifier</>}
                    defaultValue={dataColumn.publishTopic}
                    onChange={e => updateColumn(dataColumn, 'publishTopic', e.target.value || undefined)} />
                  <TextInput label="Column width"
                    defaultValue={dataColumn.width}
                    onChange={e => updateColumn(dataColumn, 'width', e.target.value)} />
                  <ToggleInput label="Filter features"
                    checked={dataColumn.filterable}
                    onCheckedChange={v => updateColumn(dataColumn, 'filterable', v)} />
                  <ToggleInput label="Sort features"
                    checked={dataColumn.sortable}
                    onCheckedChange={v => updateColumn(dataColumn, 'sortable', v)} />

                  {props.customConfig.rowEditor?.enabled && (<>
                    <ToggleInput label="Edit features"
                      checked={Boolean(dataColumn.editable?.enabled)}
                      onCheckedChange={v => updateColumnEditablePro(dataColumn, c => c.enabled = v)} />
                    {dataColumn.editable?.enabled && (<>
                      <TextInput label="Graph" withInterpolation
                        disabled={!dataColumn.editable?.enabled}
                        defaultValue={dataColumn.editable?.graph}
                        onChange={e => updateColumnEditablePro(dataColumn, c => c.graph = e.target.value)} />
                      <TextInput label="Subject" withInterpolation
                        disabled={!dataColumn.editable?.enabled}
                        defaultValue={dataColumn.editable?.subject}
                        onChange={e => updateColumnEditablePro(dataColumn, c => c.subject = e.target.value)} />
                      <TextInput label="Predicate" withInterpolation
                        disabled={!dataColumn.editable?.enabled}
                        defaultValue={dataColumn.editable?.predicate}
                        onChange={e => updateColumnEditablePro(dataColumn, c => c.predicate = e.target.value)} />
                      <SelectionInput
                        label="Object type"
                        defaultValue={dataColumn.editable?.objectType}
                        onValueChange={v => updateColumnEditablePro(dataColumn, c => c.objectType = v as EditableColumnConfig['objectType'])}
                        options={toObjectTypeLabel} />
                      <SelectionInput
                        label="Input field type"
                        defaultValue={dataColumn.editable?.componentType}
                        onValueChange={v => updateColumnEditablePro(dataColumn, c => c.componentType = v as EditableColumnConfig['componentType'])}
                        options={toComponentTypeLabel} />
                      <SelectionInput label="Behaviour when empty field"
                        defaultValue={dataColumn.editable?.whenEmptyField}
                        onValueChange={v => updateColumnEditablePro(dataColumn, c => c.whenEmptyField = v as EditableColumnConfig['whenEmptyField'])}
                        options={toWhenEmptyFieldOptionLabel} />
                    </>)}


                  </>)}
                </TabsContent>
              </>)}

            const parsedColumn = isDataColumn(column) ? parsed.dataColumn : parsed.actionColumn
            if (!parsedColumn.success) console.error(parsedColumn.error)
            return (
              <TabsContent key={column.id} value={column.id} className={tabsContentCss}>
                {!parsedColumn.success && (
                  <div>
                    {parsedColumn.error.issues.map(issue => (
                      <InvalidFieldMessage key={issue.message} zodIssue={issue} />))}
                      <div>For more info, see browser dev tools console.</div>
                  </div>)}
                <JsonEditor name={props.name} value={column}
                  onValueChange={value => {
                    const newColumn = value as ColumnConfig
                    replaceColumn(column.id, newColumn)
                    setTabId(newColumn.id)
                  }} />
              </TabsContent>
            )

        })}</Tabs>
      </div>
    </div>
  )
}

function TextInputWithSuggestions(props: {
  label: string
  placeholder: string
  selected: string | undefined
  select: (id: string) => void
  suggestions: string[]
  noSuggestionsPlaceholder: string
}) {
  return (
    <Label className="grid w-full gap-1.5">
      <span>{props.label}</span>
      <InputWithSuggestions
        placeholder={props.placeholder}
        selected={props.selected} select={props.select}
        suggestions={props.suggestions}
        noSuggestionsPlaceholder={props.noSuggestionsPlaceholder}
      />
    </Label>
  )
}

function ToggleInput(props: {
  label: string
  checked: boolean | undefined
  onCheckedChange: (checked: boolean) => void
}) {
  return (
    <Label className="flex items-center space-x-2 w-fit cursor-pointer">
      <Switch checked={props.checked} onCheckedChange={props.onCheckedChange} />
      <span>{props.label}</span>
    </Label>
  )
}

function TextInput(props: {
  disabled?: boolean
  label: React.ReactNode
  defaultValue: string | undefined
  onChange: React.ChangeEventHandler<HTMLInputElement>
  withInterpolation?: boolean
}) {
  return (
    <Label className="grid w-full gap-1.5">
      <div className="inline-flex justify-between">
        <span>{props.label}</span>
        {props.withInterpolation &&
        <i className="justify-self-end">topic {"{{variables}}"} enabled</i>}
      </div>
      <Input type="text" disabled={props.disabled}
        defaultValue={props.defaultValue} onChange={props.onChange} />
    </Label>
  )
}

function SelectionInput<Value extends string, Label extends string>(props: {
  label: string
  defaultValue: Value | undefined
  onValueChange: (value: Value) => void
  options: Record<Value, Label>
}) {
  return (
    <Label className="grid w-full gap-1.5">
      <span>{props.label}</span>
      <Select defaultValue={props.defaultValue} onValueChange={props.onValueChange}>
        <SelectTrigger>
          <SelectValue />
        </SelectTrigger>
        <SelectContent>{mapRecordToList(props.options, (value, label) => (
          <SelectItem key={value} value={value}>{label}</SelectItem>
        ))}</SelectContent>
      </Select>
    </Label>
  )
}

function InvalidCombinationReports(props: {parsedError: z.ZodError<TablePlusPlusCustomConfig>}) {
  const flattened = props.parsedError.flatten()
  const fieldErrors = flattened.fieldErrors as Record<string, undefined | string[]>

  const messages: string[] = []
  let singleFieldErrorCount = 0
  for (const path in fieldErrors) {
    if (path !== CUSTOM_COMBINATION_PATH) {
      singleFieldErrorCount++
      continue
    }

    fieldErrors[path]?.forEach(m => messages.push(m))
  }
  if (singleFieldErrorCount) messages.unshift(singleFieldErrorCount === 1
    ? "1 field above is invalid"
    : `${singleFieldErrorCount} fields above are invalid`
  )

  return (<>{
    messages.map(message => (
      <Alert key={message} className="bg-red-800 text-white">
        <AlertDescription>{message}</AlertDescription>
      </Alert>))
  }</>)
}

function InvalidFieldMessage(props: {zodIssue: z.ZodIssue}) {
  const location = props.zodIssue.path
    .map(keyOrIndex => {
      switch(typeof keyOrIndex) {
        case "string": return `key "${keyOrIndex}"`
        case "number": return `index ${keyOrIndex}`
        default: return `unknown ${keyOrIndex}`
      }
    })
  .join(' > ')

  return (
    <Alert className="bg-red-800 text-white"><AlertDescription>
      <code className="text-pink-300">{props.zodIssue.code}</code>
      {' at '}
      <code className="text-pink-300">{location}</code>
    </AlertDescription></Alert>
  )
}
