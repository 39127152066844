import React, { Component } from 'react'
import { connect } from 'react-redux'
import styles from './Project.module.css'
import orderBy from 'lodash/orderBy'
import Header from './Header'
import add from '../images/add.svg'
import BackButton from './BackButton'
import axios from 'axios'
import Spinner from './Spinner'
import { Helmet } from 'react-helmet'
import Footer from './Footer'
import { DeleteAppConfigButton } from './DeleteAppConfigButton'
import { deleteAppConfig } from '../api/delete-app-config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faLockOpen, faDatabase, faXmark } from '@fortawesome/free-solid-svg-icons';

function makeID() {
  var text = ''
  var possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  for (var i = 0; i < 5; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length))
  return text
}

class Project extends Component {
  componentDidMount() {
    let projectPath = this.props.match.params.projectPath
    let project = this.props.project
    if (project.path === projectPath && !project.loading) return
    axios({
      method: 'get',
      url: '/api/projects/' + projectPath,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('kvk-vb-token')}`,
      },
    }).then(({ data }) => {
      this.props.dispatch({
        type: 'FETCH_PROJECT_SUCCESS',
        data,
      })
    })
  }

  newClicked = () => {
    let path = makeID()
    this.props.history.push(`/projects/${this.props.project.path}/${path}/edit`)
  }

  /** @param {string} id */
  deleteApp = async (id) => deleteAppConfig(id, this.props.dispatch)

  renderViewers(viewers, isAuthenticated) {
    if (!viewers.length)
      return (
        <div className={styles.noViewerMessage}>
          No apps found in this project... {!isAuthenticated && 'Maybe when you login.'}
        </div>
      )
    return (
      <div>
        {viewers.map((viewer) => {
          return (
            <div
              className={styles.viewer}
              key={viewer.path}
              onClick={() =>
                this.props.history.push(
                  `/projects/${this.props.project.path}/${viewer.path}`
                )
              }
            >
              <div className={styles.titleText}>{viewer.title}</div>
              <div className={styles.viewerIcon} data-tooltip={`This app is ${viewer.public ? 'public' : 'private'}`}>
                  <FontAwesomeIcon
                    icon={viewer.public ? faLockOpen : faLock}
                  />
                </div>
                <div className={styles.viewerIcon} data-tooltip={`This app is ${viewer.noConfigCache ? 'not using config cache' : 'using cache'}`}>
                  <FontAwesomeIcon
                    icon={viewer.noConfigCache ? faXmark : faDatabase}
                  />
                </div>
              {viewer.user != null && (
                <div style={{ fontSize: '0.9em', opacity: '0.6' }}>
                  {viewer.user.name}
                </div>
              )}
              {hasAuth(this.props.loginState, viewer.user) &&
                <DeleteAppConfigButton
                  deleteAppConfig={() => this.deleteApp(viewer.id)}
                  title={viewer.title}
                  createdBy={viewer.user.name}
                  createdOn={viewer.createdAt}
                />
              }
              
            </div>
          )
        })}
      </div>
    )
  }

  render() {
    let project = this.props.project
    if (project.loading) return <Spinner />
    let viewers = project.viewers

    /** @type {LoginState} */
    const loginState = this.props.loginState

    // viewers = orderBy(viewers, ['createdAt'], ['asc']);
    viewers = orderBy(viewers, ['title'], ['asc'])
    return (
      <div>

        <Helmet>
          <title>{project.title}</title>
          <link
            rel="icon"
            type="image/png"
            href={`/favicon-${project.path}.png`}
            sizes="16x16"
          />
        </Helmet>

        <BackButton to="/" />
        <Header />

        <div className={styles.mainContainer}>

          <div className={styles.main}>
            <div className={styles.content}>
              <div
                className={styles.title}
              >{`Wistor Apps in ${this.props.project.title}`}</div>
              {/* <div className={styles.title}>Selecteer een viewer</div> */}
              <div className={styles.viewers}>
                {this.renderViewers(viewers, loginState.isLoggedIn)}
                {loginState.isLoggedIn && loginState.user.role !== 'viewer' && (
                  <div className={styles.viewer} onClick={this.newClicked}>
                    <div>
                      <img className="inline" src={add} alt="add" style={{ width: '24px' }} />
                      <span
                        style={{ verticalAlign: 'middle', paddingLeft: '12px' }}
                      >
                        Create a new app...
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <Footer />
        </div>
      </div>
    )
  }
}

const emailsWithAnyDeletionRights = [
  'keon.chen@bim-connected.com',
  'marcin.koziuk@bim-connected.com',
  'norent.khy@bim-connected.com',
  'richa.mehta@bim-connected.com',
  'robin.vantol@bim-connected.com',
].map(s => s.toLowerCase())

/**
 * @param loginState {LoginState}
 * @param appCreator { null | { id: string, email: string, role: string } }
 * @return {boolean}
 */
function hasAuth(loginState, appCreator) {
  if (!loginState.isLoggedIn)
    return false
  if (loginState.user.role !== 'superadmin')
    return false
  if (emailsWithAnyDeletionRights.includes(loginState.user.email.toLowerCase()))
    return true
  if (appCreator && loginState.user.id === appCreator.id)
    return true
  return false
}

const mapStateToProps = (state) => ({
  project: state.project,
  loginState: state.login
})

export default connect(mapStateToProps)(Project)
