import React from 'react'
import PublishUtils from '../../../helpers/PublishUtils'

export const configOptions = [
  {
    name: 'query',
    type: 'yasgui',
    label: 'Query for info. At the moment it is not used.',
  },
  {
    name: 'llmModel',
    type: 'text',
    label: 'Open AI model to choose from. Different model costs differently. Be careful for the cost.',
  },
  {
    name: 'llmApiEndpoint',
    type: 'text',
    label: 'The base URL. Used if other openAI API compatible models like Deepseek.',
  },
  {
    name: 'llmApiKey',
    type: 'text',
    label: 'The OpenAI API key',
  },
  {
    name: 'graphName',
    type: 'text',
    label: 'The name of the graph that the model can run SPARQL in',
  },
  {
    name: 'userAvatar',
    type: 'text',
    label: 'Image path to the Avatar of the user',
  },
  {
    name: 'assistantAvatar',
    type: 'text',
    label: 'Image path to the Avatar of the assistant',
  },
  PublishUtils.getTimestampParametersDef(),
]

export const graphRAGChatDescription = {
  name: 'GraphRAGChat',
  component: React.lazy(() => import('./GraphRAGChat')),
  label: 'GraphRAGChat [Demo]',
  variables: configOptions,
}
