import React from 'react'
import { ConfigurablePropsFromDescription } from '../../../helpers/widget'
import PublishUtils from '../../../helpers/PublishUtils'

export const configOptions = [
  {
    name: 'query',
    type: 'yasgui',
    label: 'Query where all possible options for the select box are queried as `?label` and `?value`.',
  },
  {
    name: 'publishTopic',
    type: 'text',
    label: 'Topic to publish selection to'
  },
  {
    name: 'listenTopic',
    type: 'text',
    label: 'Topic to listen to for selection'
  },
  {
    name: 'placeholder',
    type: 'text',
    label: 'Empty selection placeholder text'
  },
  {
    name: 'noResultsMessage',
    type: 'text',
    label: 'Message for no selection options found'
  },
  PublishUtils.getMultipleValueFormatOptions(),
] as const

export type MultipleSelectorConfigOptions = ConfigurablePropsFromDescription<typeof configOptions>

export const multipleSelectorDescription = {
  name: 'MultipleSelector',
  component: React.lazy(() => import('./MultipleSelectBox')),
  label: 'MultipleSelector',
  variables: configOptions,
}
