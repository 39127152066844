import { Component } from 'react'
import { connect } from "react-redux"
import uniqueId from "lodash.uniqueid"
import styles from './Area.module.css'

import gearWhite from '../images/gear-white.svg'
import gearBlue from '../images/gear-blue.svg'
import insertIcon from '../images/plus.svg'
import infoIcon from '../images/info.svg'
import deleteIcon from '../images/cross.svg'

import PublishUtils from '../helpers/PublishUtils'
import Widget from './Widget'
import Dialog from './Dialog'
import { Grow } from '@mui/material'
import { isUserActionsDisabled } from "../redux/current-viewer"
import { WidgetWrapper } from "./WidgetWrapper"
import { LargeTooltip } from "./mui/LargeTooltip"
import { interpolateSubscriptions } from '../helpers/widget'
import { store } from "../store"
import { WidgetConfiguration, shouldApplyBorderRadius } from './WidgetConfiguration'
import { widgetDefinitionsSorted } from './widgets'

class Area extends Component {
  state = {
    dialogVisible: false,
  }

  constructor(props) {
    super(props)
    try
    {
      this.widgetId = uniqueId(`${props.config.widget}-`)
    }
    catch(e){}
  }

  componentWillUnmount() {
    // Make sure to revoke the vote when unmounting
    if (this.hasVotedForUserActionsDisabled()) {
      this.handleVoteForUserActionsDisabled(false)
    }
  }

  onInsertLayout = (name) => {
    let config = {
      ...this.props.config,
    }
    var split = null
    if (
      window.confirm(
        'Do you want this widget to be the first element in the new splitlayout'
      )
    ) {
      split = {
        areaSizes: ['auto', 'auto'],
        areas: [config, {}],
        direction: 'column',
        widget: 'SplitLayout',
      }
    } else {
      if (
        window.confirm(
          'Do you want this widget to be the second element in the new splitlayout'
        )
      ) {
        split = {
          areaSizes: ['auto', 'auto'],
          areas: [{}, config],
          direction: 'column',
          widget: 'SplitLayout',
        }
      } else {
        if (
          window.confirm(
            'Do you want this widget to be the third element in the new splitlayout or cancel all'
          )
        ) {
          split = {
            areaSizes: ['auto', 'auto', 'auto'],
            areas: [{}, {}, config],
            direction: 'column',
            widget: 'SplitLayout',
          }
        } else {
          return
        }
      }
    }

    this.props.onChange(split, this.props.index)
  }

  
  onDeleteWidget = (name) => {
    if (window.confirm('are you sure that you want to delete this?')) {
      let config = {
        ...this.props.config,
      }

      config = {}
      this.props.onChange(config, this.props.index)
    }
  }

  onChildChange = (config, index) =>
   {
    try 
    {
    let newConfig = {
      ...this.props.config,
    }
    
    newConfig.areas[index] = config
    this.props.onChange(newConfig, this.props.index)
  }
  catch(e)
  {
    console.log(e);
  }
    
  }

  onShowPopupInfo = () => {
    this.setState({ popupInfoVisible: true })
    let me = this
    setTimeout(function () {
      me.infoPopupClose()
    }, 2500)
  }

  infoPopupClose = () => {
    this.setState({ popupInfoVisible: false })
  }

  openConfigurationWindow = () => this.setState({dialogVisible: true})
  closeConfigurationWindow = () => this.setState({dialogVisible: false})

  /**
   * Not subscribing to this value from the store, for performance reasons.
   * In the conventional manner we would use mapStateToProps, but that triggers unnecessary cascading rerenders.
   * @return {boolean}
   */
  hasVotedForUserActionsDisabled() {
    return store.getState().currentViewer.votersForGlobalUserActionsDisabled.has(this.widgetId)
  }

  /**
   * @param userActionsDisabled {boolean}
   */
  handleVoteForUserActionsDisabled = (userActionsDisabled) => {
    this.props.dispatch({
      type: "VOTE_USER_ACTIONS_DISABLED",
      data: {
        voterId: this.widgetId,
        value: userActionsDisabled
      }
    })
  }

  renderWidget(config, widgetDefinition) {
    if (!config.widget) {
      if (this.props.mode === 'edit') return <div>Selecteer een widget.</div>
      return
    }
    if ((this.props.mode === 'edit') && (widgetDefinition==null)) return <div>Selecteer een widget.</div>
    if (widgetDefinition==null) return null;
    if (interpolateSubscriptions(this, config.hidden, 'boolean')) return null

    const widgetUserActionsDisabled = !!config.syncWithUserActionsDisabled && this.props.userActionsDisabled

    return (
      <WidgetWrapper
        userActionsDisabled={widgetUserActionsDisabled}
        clickable={!!this.props.onClick}
        onClick={this.props.onClick}
      >
        <Widget
          reauth={this.props.reauth}
          widgetId={this.widgetId}
          repoName={this.props.repoName}
          repoURL={this.props.repoURL}
          parameterValues={this.props.parameterValues}
          localRepoURL={this.props.localRepoURL}
          mode={this.props.mode}
          save={this.props.save}
          sparqlErrorMessage={this.props.sparqlErrorMessage}
          withinSelectedTab={this.props.withinSelectedTab}
          parseUrlProperties={this.props.parseUrlProperties}
          viewer={this.props.viewer}
          {...withTopicValues(config, widgetDefinition, this)}
          definition={widgetDefinition}
          onChange={this.onChildChange}
          closeDialog={this.props.closeDialog}
          userActionsDisabled={widgetUserActionsDisabled}
          voteForUserActionsDisabled={this.handleVoteForUserActionsDisabled}
          
        >
          <widgetDefinition.component />
        </Widget>
      </WidgetWrapper>
    )
  }

  render() {
   // if (this.props.withinSelectedTab==false) return false;

  // console.log(this);
    let config = this.props.config || {}
   




    let menubar = false
    let titleMenuBar = config.titleMenubar
    if (titleMenuBar != null) {
      titleMenuBar = titleMenuBar.trim()
      titleMenuBar = PublishUtils.processStringForParameters(this, titleMenuBar)
      titleMenuBar=PublishUtils.removeUnresolvedParametersFromString(titleMenuBar)

    }
    if (titleMenuBar != null && titleMenuBar !== '' && titleMenuBar !== ' ') {
      menubar = true
    }
    //  console.log("titleMenubar '"+titleMenuBar+"'");

    let mode = this.props.mode
    let widgetDefinition = widgetDefinitionsSorted.find(
      (widgetDefinition) => widgetDefinition.name === config.widget
    )
    try {
      var queryDef=widgetDefinition.variables.find(obj => obj.name === "query");
    if  (queryDef!=null)
    {
      //console.log("widget with query parameter")
      if (config.query==null)
      {
            config.query=queryDef.defaultQuery;
         //   console.log("setting default query");
      }
    }
  }
  catch(e){}





    let extraStyling1 = ''
    let extraStyling2 = ''
    let extraStyling3 = ''
    let extraStyling4 = ''
    if (config.panelstyleExtra != null) {
      var sts = config.panelstyleExtra.split(' ')
      try {
        extraStyling1 = sts[0]
      } catch (e) {}
      try {
        extraStyling2 = sts[1]
      } catch (e) {}
      try {
        extraStyling3 = sts[2]
      } catch (e) {}
      try {
        extraStyling4 = sts[3]
      } catch (e) {}
    }
    let displayFlex = ' ' + styles.outerAreaContainerDisplayFlex
    //not used
    if (this.props.disableFlexbox) {
      //  console.log("disable flexbox for this area")  ;
      displayFlex = ''
    }
    let infoMenubarb = false
    if (config.infoMenubar != null) {
      if (config.infoMenubar.length > 2) {
        infoMenubarb = true
      }
    }
    var appear=true;
    var hidden = config.hidden;
    if (hidden==null) hidden=false;
    if (hidden!=false) {
    
      hidden=PublishUtils.processStringForParameters(this,hidden);
      try {hidden=JSON.parse(hidden.toLowerCase());} catch(e){}
      //console.log("2",hidden,mode);
      //if (hidden==true) console.log("3 hidden=true");
      //if (hidden==false) console.log("4 hidden=false");
     
    }
    var hidden2= config.hiddenwhenparametersareempty;
    if (hidden2?.length)
    {
      
      var hids2=hidden2.split(",");
    //console.log("hidden2",hids2);
      for (var n in hids2)
      {
        var hid2=hids2[n];
         if (this.props.pubsub[hid2]==null) hidden=true;
         if (this.props.pubsub[hid2]=="http://www.buildingbits.nl/reset") hidden=true;
      }
    }

    
    var visibilityState =hidden ? "hidden" : "visible";
 if (mode==='edit')  {  visibilityState="visible"; }

      


    return (
     <Grow appear={appear} timeout={1000} in={true}  style={{visibility: visibilityState}} >
        <div 
          className={
            styles.outerAreaContainer +
            ' ' +
            (shouldApplyBorderRadius(config.widget) ? '' : styles.zeroBorderRadiusWidget) +
            ' ' +
            displayFlex +
            ' ' +
            (mode === 'edit' ? '' : styles.viewMode) +
            ' ' +
            styles[config.widget] +
            ' ' +
            styles[config.panelstyle] +
            ' ' +
            styles[extraStyling1] +
            ' ' +
            styles[extraStyling2] +
            ' ' +
            styles[extraStyling3] +
            ' ' +
            styles[extraStyling4]
          }
          style={{
            '--area-border-style': config.areaBorderStyle,
            '--area-border-radius': config.areaBorderRadius,
            '--final-top-left-border-radius': config.finalTopLeftBorderRadius,
            '--final-top-right-border-radius': config.finalTopRightBorderRadius,
            '--final-bottom-left-border-radius': config.finalBottomLeftBorderRadius,
            '--final-bottom-right-border-radius': config.finalBottomRightBorderRadius,
          }}
          data-custom-styling={Boolean(config.customStyling)}
          data-final-top-left-border-radius={!!config.finalTopLeftBorderRadius}
          data-final-top-right-border-radius={!!config.finalTopRightBorderRadius}
          data-final-bottom-left-border-radius={!!config.finalBottomLeftBorderRadius}
          data-final-bottom-right-border-radius={!!config.finalBottomRightBorderRadius}
          data-widget-type={config.widget}
        >
          {mode === 'edit' && (
            <div className={styles.hoverToEdit}>
              <div className={styles.hoverIndicator} aria-label="Hover to reveal widget toolbar" />
              <LargeTooltip
                placement="bottom"
                title={config.widget && `${config.widget} widget`}
              >
                <div className={styles.editArea} role="toolbar" aria-label="Widget toolbar">
                  <img
                    src={insertIcon}
                    alt="box"
                    className={styles.insertIcon}
                    aria-label="Insert widget"
                    onClick={() => this.onInsertLayout(config.widget)}
                  />
                  <img
                    src={'areas' in config ? gearBlue : gearWhite}
                    alt="gear"
                    className={styles.editIcon}
                    aria-label="Configure widget"
                    onClick={this.openConfigurationWindow}
                  />
                  <img
                    src={deleteIcon}
                    alt="cross"
                    className={styles.insertIcon}
                    aria-label="Delete widget"
                    onClick={() => this.onDeleteWidget(config.widget)}
                  />
                </div>
              </LargeTooltip>
            </div>
          )}

          {menubar && (
            <div className={styles.menubar}>
              <div className={styles.menubartxt}> {titleMenuBar}</div>
              {infoMenubarb && (
                <img
                  src={infoIcon}
                  alt="box"
                  className={styles.insertIcon}
                  onClick={() => this.onShowPopupInfo()}
                />
              )}
            </div>
          )}

          {this.renderWidget(config, widgetDefinition)}

          {this.state.popupInfoVisible && (
            <Dialog onClose={this.infoPopupClose}>
              <div>{config.infoMenubar}</div>
            </Dialog>
          )}

          {this.state.dialogVisible && (
            <WidgetConfiguration
              configRendered={config}
              widgetDefinition={widgetDefinition}
              close={this.closeConfigurationWindow}
              saveConfigRendered={this.props.save}
              setConfigToRender={config => this.props.onChange(config, this.props.index)}
            />
          )}
        </div>
        </Grow>
     
    )
  }
}

const mapStateToProps = function (state) {
  return {
    userActionsDisabled: isUserActionsDisabled(state.currentViewer)
  }
}

function withTopicValues(config, widgetDefinition, widget) {
  if (config==null)
    {
      console.log("this is weird");
      config={};
    }
  const newConfig = {...config}  // sometimes config is null resulting in errors here.
  const widgetDefinitionVariables = widgetDefinition?.variables ?? []
  for (const variable of widgetDefinitionVariables) {
    if (variable.topicValueInterpolationEnabled) {
      if (Array.isArray( newConfig[variable.name])) {
        newConfig[variable.name] = newConfig[variable.name].map(valueString => PublishUtils.processStringForParameters(widget, valueString))
      }
      else if (typeof newConfig[variable.name] === 'string') {
        newConfig[variable.name] = PublishUtils.processStringForParameters(widget, newConfig[variable.name])
      }
    }
  }
  return newConfig
}

/**
 * Workaround until proper typing
 * source: https://stackoverflow.com/a/49806135
 * @type {React.ComponentType<any>}
 */
export default connect(mapStateToProps)(Area)
