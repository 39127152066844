import { lazy } from 'react';
export default class BBMJOPWidgetDescriptor{  

static getDescription() {
  
    return {
      name: 'BBMJOPwidget',
      component: lazy(() =>  import('./BBMJOPWidget.js')),
      label: 'MJOP Widget (experimental)',
      type:"experimental",
      variables: [
       
        {
          name: 'query',
          type: 'yasgui',
          label: 'use parameter... (not yet implemented)  ',
          defaultValue: `prefix m:<http://wistor.nl/mjop/>
select distinct ?uri ?nr ?locatie ?beschrijving ?conditie ?cyclus ?aantal ?eenheid ?procent ?prijsPerEenheid ?btw ?startDatum ?kostenInc ?kostenEx ?totaal ?gemiddeldePerJaar ?opmerkingen ?jaar2025 ?jaar2026 ?jaar2027 ?jaar2028 ?jaar2029 ?jaar2030 ?jaar2031 ?jaar2032 ?jaar2033 ?jaar2034 ?jaar2035 ?jaar2036 ?jaar2036 ?jaar2038 ?jaar2039 ?jaar2040 ?jaar2041 ?jaar2042 ?jaar2043 ?jaar2044 ?jaar2045
 from <http://wistor.nl/mjop/data>
where
{
  ?uri m:nr ?nr.
  optional {?uri m:locatie ?locatie}
  optional {?uri m:beschrijving ?beschrijving}
  optional {?uri m:conditie ?conditie}
  optional {?uri m:cyclus ?cyclus}
  optional {?uri m:aantal ?aantal}
  optional {?uri m:eenheid ?eenheid}
  optional {?uri m:procent ?procent}
  optional {?uri m:prijsPerEenheid ?prijsPerEenheid}
  optional {?uri m:btw ?btw}
  optional {?uri m:startDatum ?startDatum}
  optional {?uri m:kostenInc ?kostenInc}
  optional {?uri m:kostenEx ?kostenEx}
  optional {?uri m:totaal ?totaal}
  optional {?uri m:gemiddeldePerJaar ?gemiddeldePerJaar}
  optional {?uri m:opmerkingen ?opmerkingen}
  optional {?uri m:jaar2025 ?jaar2025}
  optional {?uri m:jaar2026 ?jaar2026}
  optional {?uri m:jaar2027 ?jaar2027}
  optional {?uri m:jaar2028 ?jaar2028}
  optional {?uri m:jaar2029 ?jaar2029}
  optional {?uri m:jaar2030 ?jaar2030}
  optional {?uri m:jaar2031 ?jaar2031}
  optional {?uri m:jaar2032 ?jaar2032}
  optional {?uri m:jaar2033 ?jaar2033}
  optional {?uri m:jaar2034 ?jaar2034}
  optional {?uri m:jaar2035 ?jaar2035}
  optional {?uri m:jaar2036 ?jaar2036}
  optional {?uri m:jaar2037 ?jaar2037}
  optional {?uri m:jaar2038 ?jaar2038}
  optional {?uri m:jaar2039 ?jaar2039}
  optional {?uri m:jaar2040 ?jaar2040}
  optional {?uri m:jaar2041 ?jaar2041}
  optional {?uri m:jaar2042 ?jaar2042}
  optional {?uri m:jaar2043 ?jaar2043}
  optional {?uri m:jaar2044 ?jaar2044}
  optional {?uri m:jaar2045 ?jaar2045}

     
  
  
}`
  
        },
        {
          name: 'defaultgraph',
          type: 'text',
          label: 'default graph to insert data into',
        },
        {
          name: 'relatedTo',
          type: 'text',
          label: 'http://wistor.nl/mjop/relatedTo value for each new record',
        },
        {
          name: 'minYear',
          type: 'text',
          label: 'minimum year for the MJOP defaultvalue is 2025',
        },
        {
          name: 'maxYear',
          type: 'text',
          label: 'maximum year for the MJOP default value is 2045',
        },
        {
          name: 'timestampParameters',
          type: 'text',
          label:
            'sets a timestamp after main event. multiple parameters via comma seperation',
        }
      
       
       
              ],
    }
  }
}
