import React from 'react'
import { original, produce } from 'immer'
import styles from './WidgetConfiguration.module.css'

import Dialog from './Dialog'
import { Button } from 'reactstrap'
import { FormGroup } from './FormGroup'
import FormField from './FormField'

import { ConfigDescription, ConfigurablePropsFromDescription } from '../helpers/widget'
import { widgetDefinitionsSorted } from './widgets'
import { DEFAULT_WIDGET_CONFIG } from './default-widget-config'
import { forEachEntry } from '../utils/typescript'
import { useDebounce } from '../hooks/use-debounce'

type WidgetConfig
  = GeneralWidgetConfig
  & ConfigurablePropsFromDescription<AnyWidgetDefinition['variables']>

type GeneralWidgetConfig = {
  widget: string
  panelstyle: string
  panelstyleExtra: string
  titleMenubar: string
  infoMenubar: string
  colorPrimaryAction: boolean
  showLoadingOverlay: boolean
  syncWithUserActionsDisabled: boolean
  voteForUserActionsDisabledWhenLoading: boolean
  hidden: string
  finalTopLeftBorderRadius: string
  finalTopRightBorderRadius: string
  finalBottomLeftBorderRadius: string
  finalBottomRightBorderRadius: string
  hiddenwhenparametersareempty: string
}

type AnyWidgetDefinition = {
  label: string,
  name: string,
  variables: ConfigDescription<any, any>[],
}

type State = {
  waitingToSaveAndClose: boolean
  configInput: WidgetConfig
  configOriginal: WidgetConfig
  feedback: {
    warning: Record<string, React.ReactNode>
    error: Record<string, React.ReactNode>
  },
}

export function WidgetConfiguration(props: {
  configRendered: WidgetConfig
  widgetDefinition?: AnyWidgetDefinition
  close: () => void
  saveConfigRendered: () => void
  setConfigToRender: (config: WidgetConfig) => void
}) {
  const [state, setState] = React.useState<State>(() => ({
    waitingToSaveAndClose: false,
    feedback: {warning: {}, error: {}},
    configInput: props.configRendered,
    configOriginal: props.configRendered,
  }))

  React.useEffect(() => {
    if (!state.waitingToSaveAndClose) return
    if (props.configRendered !== state.configInput) return
    props.saveConfigRendered()
    props.close()
  }, [state.waitingToSaveAndClose, props.configRendered])

  // ref, because we want it instantly updated without need for reactivity
  const delayedUpdateEnabled = React.useRef<boolean>(true)

  const updateConfigField = (key: string, value: any) =>
    setState(produce(draft => {
      if (key === 'widget') {
        mutateWidgetTypeWithDefaults(draft.configInput, value)
      } else {
        draft.configInput = {...draft.configInput, [key]: value}
      }
    }))

  useDebounce(() => {
    if (state.configInput === props.configRendered) return
    if (delayedUpdateEnabled.current) props.setConfigToRender(state.configInput)
  }, 200, [state.configInput])

  const setConfigFeedback = (
    varName: string, type: 'valid' | 'warning' | 'error', message?: React.ReactNode
  ) => setState(produce(draft => {
    switch (type) {
      case 'valid':
        delete draft.feedback.error[varName]
        delete draft.feedback.warning[varName]
        return
      case 'error':
        delete draft.feedback.warning[varName]
        draft.feedback.error[varName] = message
        return
      case 'warning':
        delete draft.feedback.error[varName]
        draft.feedback.warning[varName] = message
        return
      default:
        throw new Error('Oops should be unreachable, dev spaghetti. If this happens often, migrate (partially) to TypeScript')
    }
  }))

  const widgetDefinition = props.widgetDefinition ?? widgetDefinitionsSorted.find(w => w.name === state.configInput.widget)

  return (
    <Dialog onClose={undefined}>
      <FormField
        name="widget"
        label="Widget"
        type="searchselect"
        placeholder="Search widgets..."
        noResultsLabel="No widgets found"
        options={[
          { label: 'Selecteer een widget', value: '' },
          ...widgetDefinitionsSorted.map((wd) => ({
            label: wd.label,
            value: wd.name,
          })),
        ]}
        value={state.configInput.widget}
        onChange={updateConfigField}
      />
      <FormField
        name="panelstyle"
        label="panel style"
        type="searchselect"
        placeholder="Search panel styles..."
        noResultsLabel="No styles found"
        options={[
          { label: 'Selecteer een style', value: '' },
          { label: 'standaard', value: 'standaard' },
          { label: 'no scrollBars', value: 'noScrollBars' },
          { label: 'toolbar', value: 'toolbar' },
          { label: 'border', value: 'border' },
          { label: 'border 2', value: 'border2' },
          { label: 'paper', value: 'paper' },
          { label: 'paper 2', value: 'paper2' },
          { label: 'padding', value: 'padding1' },
          { label: 'padding 2', value: 'padding2' },
          { label: 'padding 3', value: 'padding3' },
          { label: 'none', value: 'none' },
          { label: 'none white', value: 'noneWhite' },
          { label: 'refresh', value: 'refresh' },
          { label: 'three color template', value: 'threeColorTemplate' },
        ]}
        value={state.configInput.panelstyle}
        onChange={updateConfigField}
      />
      <FormField
        name="panelstyleExtra"
        label="panel style  (noScrollBars)"
        type="text"
        value={state.configInput.panelstyleExtra}
        onChange={updateConfigField}
      />

      <FormField
        name="titleMenubar"
        label="title menubar"
        type="text"
        value={state.configInput.titleMenubar}
        onChange={updateConfigField}
      />
      <FormField
        name="infoMenubar"
        label="Info popup menubar"
        type="text"
        value={state.configInput.infoMenubar}
        onChange={updateConfigField}
      />
      <FormField
        name="colorPrimaryAction"
        label='give primary action color (when panel style "three color template")'
        type="boolean"
        value={state.configInput.colorPrimaryAction}
        onChange={updateConfigField}
      />
      <FormGroup tag="fieldset" className={styles["fieldset"]}>
        <legend>Behaviour on loading</legend>
        <FormField
          name="showLoadingOverlay"
          label="Show loading overlay while loading."
          type="boolean"
          value={state.configInput.showLoadingOverlay}
          onChange={updateConfigField}
        />
        <FormField
          name="syncWithUserActionsDisabled"
          label="User interaction disabled while other widgets are loading."
          type="boolean"
          value={state.configInput.syncWithUserActionsDisabled}
          onChange={updateConfigField}
        />
        <FormField
          name="voteForUserActionsDisabledWhenLoading"
          label="Widget blocks user interaction on other widgets while loading."
          type="boolean"
          value={state.configInput.voteForUserActionsDisabledWhenLoading}
          onChange={updateConfigField}
        />
      </FormGroup>
      <FormField
        name="hidden"
        label="hide this panel. Parameter must be true or false"
        type="text"
        topicValueInterpolationEnabled={true}
        value={state.configInput.hidden}
        onChange={updateConfigField}
      />

      {shouldApplyBorderRadius(state.configInput.widget) && (
        <>
          <FormField
            name="finalTopLeftBorderRadius"
            label="final top-left border radius"
            type="text"
            value={state.configInput.finalTopLeftBorderRadius}
            onChange={updateConfigField}
          />
          <FormField
            name="finalTopRightBorderRadius"
            label="final border top-right radius"
            type="text"
            value={state.configInput.finalTopRightBorderRadius}
            onChange={updateConfigField}
          />
          <FormField
            name="finalBottomLeftBorderRadius"
            label="final bottom-left border radius"
            type="text"
            value={state.configInput.finalBottomLeftBorderRadius}
            onChange={updateConfigField}
          />
          <FormField
            name="finalBottomRightBorderRadius"
            label="final bottom-right border radius"
            type="text"
            value={state.configInput.finalBottomRightBorderRadius}
            onChange={updateConfigField}
          />
        </>
      )}
      <FormField
        name="hiddenwhenparametersareempty"
        label="comma separated parameters that need to have a value"
        type="text"
        value={state.configInput.hiddenwhenparametersareempty}
        onChange={updateConfigField}
      />

      {widgetDefinition?.variables?.map((variable) => (
          <FormField
            key={variable.name}
            name={variable.name}
            label={variable.label}
            type={variable.type}
            options={'options' in variable ? variable.options : undefined}
            value={(state.configInput as any)[variable.name]}
            onChange={updateConfigField}
            setConfigFeedback={(type, message) => setConfigFeedback(variable.name, type, message)}
          />
        ))}

      {Boolean(Object.keys(state.feedback.error).length) && (
        <div className="bg-slate-200 p-2 rounded-md">
          {Object.values(state.feedback.error)}
        </div>
      )}

      <div style={{ textAlign: 'center' }}>
        <Button
          color="secondary"
          style={{ minWidth: '120px', marginTop: '12px' }}
          onClick={() => {
            props.setConfigToRender(state.configOriginal)
            props.close()
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={Boolean(Object.keys(state.feedback.error).length)}
          color="primary"
          style={{ minWidth: '120px', marginTop: '12px', marginLeft: '40px' }}
          onClick={() => {
            delayedUpdateEnabled.current = false
            props.setConfigToRender(state.configInput)
            setState(s => ({...s, waitingToSaveAndClose: true}))
          }}
        >
          OK
        </Button>
      </div>
    </Dialog>
  )
}

function mutateWidgetTypeWithDefaults<T extends WidgetConfig>(config: T, widget: T['widget']) {
  config.widget = widget

  if (
    widget === 'SplitLayout' || widget === 'SplitLayoutTest' ||
    widget === 'BBKVKProductTemplate' || widget === 'BB ButtonPopup'
  ) {
    const layoutConfig = config as any
    layoutConfig.direction = 'column'
    layoutConfig.areas = [original(layoutConfig)]
    if (widget === 'SplitLayoutTest') {
      layoutConfig.areaSizes = ['1', '1']
    } else {
      layoutConfig.areaSizes = ['200px', '1']
    }

  } else if (widget === 'Tab' || widget === 'BBSideMenu') {
    const configWithTabs = config as any
    configWithTabs.tabs = ['Tab 1', 'Tab 2']
    configWithTabs.areas = [original(config)]

  } else {
    const childConfigOfSameType =
      (config as any).areas &&
      (config as any).areas.find((area: WidgetConfig) => area && area.widget === widget)
    if (childConfigOfSameType) config = childConfigOfSameType
  }

  forEachEntry(DEFAULT_WIDGET_CONFIG.general, (key) => {
    if (!(key in config)) config[key] = DEFAULT_WIDGET_CONFIG.general[key]
  })

  const defaultConfig = DEFAULT_WIDGET_CONFIG[config.widget as keyof typeof DEFAULT_WIDGET_CONFIG]
  if (defaultConfig != null) {
    forEachEntry(defaultConfig, (key) => {
      if (!(key in config)) config[key] = defaultConfig[key]
    })
  }
}

export function shouldApplyBorderRadius(widgetName: string) {
  if (!widgetName) return false
  return widgetName !== 'BBParameterLinkEmptyWidget' && !widgetName.includes('SplitLayout')
}
